import React from "react";
import "./payment-plan-credits.scss"
import {CreditsBundle} from "../PaymentPlan";

type Props = {
    creditsCount: number
    updateCredits: (key: number) => void
    createInvoice: (key: number, ib: boolean, id?: number) => void
    creditBundle: CreditsBundle[]
    creditSum: number
}

const bundles = [
    { bg: '#FEBC95', credits: 5, price: 500, description: "Just enough to get started with a few urgent legal requests." },
    { bg: '#BAB3F4', credits: 10, price: 1000, description: "Ideal for a steady stream of legal requests." },
    { bg: '#99CAF2', credits: 15, price: 1500, description: "Prepare in advance for more complex legal projects or multiple requests." }
]

export const PaymentPlanCredits: React.FC<Props> = ({ creditSum, creditsCount, updateCredits, createInvoice, creditBundle }) => {
    const decCredits = () => {
        if (creditsCount > 1) {
            updateCredits(creditsCount - 1)
        }
    }

    const incCredits = () => {
        updateCredits(creditsCount + 1)
    }

    return (
        <div className={'payment-plan-credits'} id={'payment-plan-credits'}>
            <div className="payment-plan-credits__heading">
                <h2>Buy Credits</h2>
                <p>Choose one of our bundles or select your preferred credit amount.</p>
            </div>
            <div className="payment-plan-credits__items">
                {
                    creditBundle.map(item => (
                        <div key={item.title} className="payment-plan-credits__item">
                            <div style={{ background: '#FEBC95' }} className="payment-plan-credits__item-heading">
                                <p>{item.count} credits</p>
                                <b>${item.price}</b>
                            </div>
                            <div className="payment-plan-credits__item-body">
                                <p>{item.description}</p>
                                <button onClick={() => createInvoice(item.count, true, item.id)} className={'text-uppercase btn-black'}>BUY CREDITS</button>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="payment-plan-credits__count">
                <div className="payment-plan-credits__credits">
                    <span>Credits:</span>
                    <button onClick={decCredits} className={'payment-plan-credits__credits-button'}>-</button>
                    <div className={'payment-plan-credits__input'}>
                        {creditsCount}
                    </div>
                    <button onClick={incCredits} className={'payment-plan-credits__credits-button'}>+</button>
                </div>
                <div><b>Price: </b>${creditSum * creditsCount}</div>
                <div className="payment-plan-credits__price">
                    <button onClick={() => createInvoice(creditsCount, false)}>BUY CREDITS</button>
                </div>
            </div>
        </div>
    )
}