import React, {useEffect, useRef, useState} from "react";
import {sendEvent} from "../../../service/tracker";

export const ViewPageDuration: React.FC<{ name: string, data: any }> = ({ name, data }) => {
  const secondsRef = useRef<number>(0);
  const [renderTrigger, setRenderTrigger] = useState<boolean>(false);
  const currentData = useRef<any>()

  const sendAmplitudeEvent = async (numb: number) => {
    data = currentData.current
    data.duration = (+numb / 60).toFixed(2)
    await sendEvent(`${name}_with_duration`, data)
  }

  const sendAmplitudeNowEvent = async () => {
    await sendEvent(name, data)
  }

  useEffect(() => {
    sendAmplitudeNowEvent()
    currentData.current = data
    const intervalId = setInterval(() => {
      secondsRef.current += 1;
      setRenderTrigger(prev => !prev);
    }, 1000);

    return () => {
      sendAmplitudeEvent(secondsRef.current)
      clearInterval(intervalId);
    }
  }, [])

  return <></>
}