import React, {useContext, useState, useCallback, useEffect} from 'react'
import LogoIcon from '../../assets/images/logo.svg'
import { Input } from '../../components/UI'
import { MainContextProvider } from '../../context/MainContext'
import {authLogin, checkResetPasswordToken} from '../../service/auth/auth'
import blackArrow from '../../assets/images/arrowBlack.svg'
import { useHistory } from 'react-router-dom'

import './login.scss'
import { useTranslation } from 'react-i18next'
import {ForgotPassword} from "../Modals/Auth/ForgotPassword/ForgotPassword";
import {ResetPassword} from "../Modals/Auth/ResetPassword/ResetPassword";
import {sendEvent} from "../../service/tracker";

const LoginPage = () => {
  const { userData } = useContext(MainContextProvider)
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());

  const [email, updateEmail] = useState('')
  const [password, updatePassword] = useState('')
  const [errorMessage, updateErrorMessage] = useState<string | undefined>('')
  const [isDisabledLoginBtn, updateIsDisabledLoginBtn] = useState(false)
  const { t } = useTranslation()

  const { setIsLoggedIn, setLoading } = useContext(MainContextProvider)

  const onLogin = useCallback(async () => {
    updateIsDisabledLoginBtn(true)

    const loginResponse = await authLogin({ email, password })
    updateIsDisabledLoginBtn(false)

    if (loginResponse.status) {
      setIsLoggedIn(true)
      // window.location.reload()
    } else {
      updateErrorMessage(loginResponse.message)
    }
  }, [email, password, setIsLoggedIn])

  const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    updateEmail(event.target.value)
    updateErrorMessage('')
  }, [])

  const onChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    updatePassword(event.target.value)
    updateErrorMessage('')
  }, [])

  useEffect(() => {
      (async () => {
          setLoading(false)
          if(query['reset-password']){
              localStorage.removeItem('initialLink')
              const { status, data } = await checkResetPasswordToken(query['reset-password'])
              if(status === 200 && JSON.parse(data).status == 'ok') setIsResetPassword(true)
          }
          document.title = "Log into Legal Nodes"
      })()

  }, [query])

  return (
    <div className={'login-page'}>
      <div className={'login-page__form'}>
        <div className={'login-page__form-header'}>
          <img src={LogoIcon} alt={''} />
        </div>
        <div className={'login-page__form-container'}>
          <div className={'login-page__heading'}>
            <h2>{t('auth.heading')}</h2>
            {/*<span>
              {t('auth.dontHaveAccount')}
              {' '}
              <a onClick={() => history.push('/registration')}>{t('auth.sign')}</a>
            </span>*/}
          </div>
          <Input
            label={'Email'}
            otherProps={{
              placeholder: t('auth.email'),
              value: email,
              onChange: onChangeEmail,
              onKeyDown: event => {
                if(event.keyCode === 13) onLogin().then()
              }
            }}
            className={'login-page__input'}
          />
          <Input
            label={'Password'}
            otherProps={{
              placeholder: t('auth.password'),
              value: password,
              onChange: onChangePassword,
              type: 'password',
              onKeyDown: event => {
                if(event.keyCode === 13) onLogin().then()
              }
            }}
            valid={!errorMessage}
            errorMessage={errorMessage}
            className={'login-page__input'}
          />
          <a onClick={() => setIsForgotPassword(true)} className={'login-page__forgot-link'}>{t('auth.forgetPassword')}</a>
          <button
            onClick={onLogin}
            disabled={isDisabledLoginBtn}
            className={'btn btn-black login-page__btn'}
          >
            {t('auth.login')}
          </button>
        </div>
        <div className={'login-page__form-footer'}>
          <span>{`© Legal Nodes ${(new Date()).getFullYear()}`}</span>
        </div>
      </div>
      <div className={'login-page__banner'}>
        <div>
          <h1 dangerouslySetInnerHTML={{ __html: t('auth.banner') }} />
          {/*<a href={'/'} className={'login-page__read-more'}>
            {t('auth.readMore')}
            <img className={'login-page__arrow'} src={blackArrow} alt={'arrow'} />
          </a>*/}
        </div>
      </div>

      <ForgotPassword
          isOpen={isForgotPassword}
          close={() => setIsForgotPassword(false)}
          initialEmail={email}
      />
      <ResetPassword
          isOpen={isResetPassword}
          close={() => {
              setIsResetPassword(false)
              history.push('/')
          }}
          token={query['reset-password']}
      />
    </div>
  )
}

export default LoginPage
